import { ref, shallowReadonly, Ref } from 'vue';
import { IInvestmentListItem } from 'Models';
import { fetchInvestmentListClosed } from '@/services/api/investment/investmentList';

const investmentListClosed: Ref<IInvestmentListItem[]> = ref([]);

export function useInvestmentListClosed() {
  async function getInvestmentListClosed() {
    const investmentListClosedResponse = await fetchInvestmentListClosed();
    investmentListClosed.value = investmentListClosedResponse.data;
    return investmentListClosedResponse;
  }

  return {
    investmentListClosed: shallowReadonly(investmentListClosed),
    getInvestmentListClosed,
  };
}
