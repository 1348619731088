import { RouterScrollBehavior } from 'vue-router';
import { getMaxScrollTop } from 'Utils/view';
import { isSkipScrollTab } from 'Utils/router';
import CONFIG from 'Config';

export const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  // for initial load 'to' and 'from' are the same object
  // but scroll is not needed if page stay the same
  if ((to !== from && to.path === from.path) || isSkipScrollTab(to, from)) {
    return false;
  }
  // return savedPosition only if scroll position is less than it can be
  // e.g. load more clicked which make page more height
  if (savedPosition && savedPosition.top <= getMaxScrollTop()) {
    return savedPosition;
  }
  // Do not try to scroll if hash is auth data
  if (to.hash && !to.hash.startsWith('#id_token')) {
    return { el: to.hash, top: CONFIG.pageScrollOffsetTop };
  }
  return { top: 0, left: 0 };
};
