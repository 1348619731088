import { computed } from 'vue';
import { useInvestmentListClosed } from './investmentListClosed';

const { investmentListClosed } = useInvestmentListClosed();

const investmentListDocumentTaxUnreadList = computed(() => {
  const cache: Record<string, boolean> = {};
  // Only successfully closed farm needed
  // For each investment
  return investmentListClosed.value.filter((item) => {
    // Get is investment has new docs
    const hasNewDocs = item.tax_documents?.some(flag => flag.read === false);
    if (!hasNewDocs) return false;
    // filter to have only one item for each farm
    // if there is item for current item farm, filter it
    if (cache[item.farm.slug]) return false;
    // otherwise save to cache current int farm
    cache[item.farm.slug] = true;
    // and return item
    return true;
  });
});

const investmentListDocumentUpdateUnreadList = computed(() => (
  investmentListClosed.value
    .filter(item => (
      item.farm.updates_document_folder
        ?.some(doc => doc?.meta_data?.read === false)
    ))
));

// Filter investmentListDocumentUpdateUnreadList to leave only one item for farm
const investmentListDocumentUpdateUnreadFarmUniqList = computed(() => {
  const cache: Record<string, boolean> = {}; // cache object
  return investmentListDocumentUpdateUnreadList.value.filter((item) => {
    // if there is item for this farm
    if (cache[item.farm.slug]) {
      // filter it
      return false;
    }
    // otherwise save to cache this farm
    cache[item.farm.slug] = true;
    // and return item
    return true;
  });
});

export function useInvestmentListClosedDocuments() {
  return {
    investmentListDocumentTaxUnreadList,
    investmentListDocumentUpdateUnreadList,
    investmentListDocumentUpdateUnreadFarmUniqList,
  };
}
