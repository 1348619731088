import { Component, markRaw } from 'vue';
import { wait } from 'Utils';
import { ModalClosedByUserError } from './ModalClosedByUserError';
import {
  IModalOptions,
  IModalOptionsArgument,
  IModalCallbacks,
  IModalProps,
} from './types';
import { useModalStore } from './modalStore';

function hideModal(modalComponent: Component) {
  const { updateModalPropsByComponent } = useModalStore();
  updateModalPropsByComponent(modalComponent, { show: false });
}

function destroyModal(modalComponent: Component) {
  useModalStore().removeModalByComponent(modalComponent);
}

export function closeModalWithOutAnimation(modalComponent: Component) {
  destroyModal(modalComponent);
}

export async function closeModalWithAnimation(modalComponent: Component) {
  hideModal(modalComponent);
  await wait(200);
  destroyModal(modalComponent);
}

/**
 * Close modal
 * @param modalComponent
 * @param animation - close modal with animation or not
 */
export async function closeModal(modalComponent: Component, animation = true) {
  if (animation) {
    await closeModalWithAnimation(modalComponent);
  } else {
    closeModalWithOutAnimation(modalComponent);
  }
}

/**
 * Get callbacks as hooks to call on events of modal lifecycle and fulfill the promise
 * @param modalComponent
 * @param options - modal options
 * @param resolve - promise resolve
 * @param reject - promise reject
 */
function _getCallbacks(
  modalComponent: Component,
  options: IModalOptions,
  resolve: (value: unknown) => void,
  reject: (error: Error) => void,
) {
  const callbacks: IModalCallbacks = {};

  callbacks.onError = async (error: Error) => {
    reject(error);
    if (options.closeOnError) await closeModal(modalComponent);
  };

  callbacks.onSuccess = async (result: unknown) => {
    resolve(result);
    if (options.closeOnSuccess) await closeModal(modalComponent);
  };

  callbacks.onCancel = async () => {
    if (options.successOnUserClose) {
      resolve(true);
    } else {
      reject(new ModalClosedByUserError());
    }
    await closeModalWithAnimation(modalComponent);
  };

  return callbacks;
}

/**
 * Get normalized options for modal
 * @param options - modal options
 *
 */
function _getNormalizedOptions(options: IModalOptions | false | undefined) {
  const normOptions: IModalOptions = {
    closeOnSuccess: true,
    successOnUserClose: true,
  };
  if (options === false) {
    normOptions.successOnUserClose = false;
  } else {
    Object.assign(normOptions, options);
  }
  return normOptions;
}

function _getNormalizedProps(props: IModalProps = {}, options: IModalOptions) {
  const normProps: IModalProps = { ...props };
  if (options.noAnimationOnOpen) {
    normProps.noAnimationOnOpen = true;
  }
  return normProps;
}

/**
 * Open modal and return promise
 * @param component {Component} - modal component
 * @param props {IModalProps} - modal props
 * @param options - modal options
 */
export function openModal(
  component: Component,
  props?: IModalProps,
  options?: IModalOptionsArgument,
): Promise<unknown> {
  const normalizedOptions = _getNormalizedOptions(options);
  const normalizedProps = _getNormalizedProps(props, normalizedOptions);

  return new Promise((resolve, reject) => {
    const callbacks = _getCallbacks(component, normalizedOptions, resolve, reject);

    useModalStore().addModal({
      component: markRaw(component),
      props: normalizedProps,
      options: normalizedOptions,
      callbacks,
    });
  });
}
