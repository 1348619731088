// TODO: rename to farmListClosed
import { ref, readonly, Ref } from 'vue';
import CONFIG from 'Config';
import { IFarmItem } from 'Models';
import { getUrlParamsByObject } from 'Utils/url';

const farmListClosed: Ref<IFarmItem[]> = ref([]);

export function useFarmListClosed() {
  async function getFarmListClosed(limit = 10000, s?: string) {
    const data = await fetch(`${CONFIG.urls.farm}/closed${getUrlParamsByObject({ limit, s })}`)
      .then(response => response.json());
    farmListClosed.value = data.data;
    return data;
  }

  return {
    farmListClosed: readonly(farmListClosed),
    getFarmListClosed,
  };
}
