import { ref, readonly, Ref } from 'vue';
import { IFarmItem } from 'Models';
import { fetchFarmListSold } from '@/services/api/farm';

const soldFarmList: Ref<IFarmItem[]> = ref([]);

export function useSoldFarmList() {
  async function getSoldFarmList(limit = 10000) {
    const farmListSoldResponse = await fetchFarmListSold(limit);
    soldFarmList.value = farmListSoldResponse.data;
    return soldFarmList.value;
  }

  return {
    soldFarmList: readonly(soldFarmList),
    getSoldFarmList,
  };
}
