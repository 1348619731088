import CONFIG from 'Config';
// eslint-disable-next-line import/no-cycle
import { IFarmItem } from 'Models';
import { getUrlParamsByObject } from 'Utils/url';
import { request } from '../request';
import { IListResponseData } from '../types';

export function fetchFarmListSold(limit = 10000): Promise<IListResponseData<IFarmItem>> {
  return request<IListResponseData<IFarmItem>>(`${CONFIG.urls.farm}/exited${getUrlParamsByObject({ limit, order: 'exited' })}`);
}

// TODO doublecheck with backened if this is correct request
// and rename properly
export function fetchFarmListOpenedSortedByWeight(): Promise<IListResponseData<IFarmItem>> {
  return request<IListResponseData<IFarmItem>>(`${CONFIG.urls.farm}/?s=weight&s=subscribed_shares`);
}
