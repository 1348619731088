<script lang="ts" setup>
import {
  ref,
  watch,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  withDefaults,
  Ref,
  useSlots,
} from 'vue';
import { generateIdShort } from 'Utils';

// const
const BODY_SCROLL_LOCK_CLASS = 'is--modal-open';
const slots = useSlots();

const props = withDefaults(defineProps<{
  show: boolean;
  class?: string;
  size?: 'full' | 'small' | 'large';
  hideCloseButton?: boolean;
  noAnimationOnOpen?: boolean;
  solidHeader?:boolean
  // Means this modal is the only one on the page and can't be open another modal in the same time
  singleModal?: boolean;
}>(), {
  size: 'full',
});

const emit = defineEmits<{
  (e: 'update:show', show: boolean): void;
  (e: 'close'): void;
}>();

// data
const id = generateIdShort();
const bodyScrollLockClassWithId = `${BODY_SCROLL_LOCK_CLASS}--${id}`;
const isShow = ref(false);
const modal: Ref<null | HTMLElement> = ref(null);

// computed
const isFull = computed(() => props.size === 'full');
const isLarge = computed(() => props.size === 'large');
const isSmall = computed(() => props.size === 'small');

// methods
function updateEmit() {
  emit('update:show', isShow.value);
  if (!isShow.value) emit('close');
}

/**
 * Clear all body scroll lock classes in html element
 */
function clearAllBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.forEach((className) => {
    if (className.startsWith(BODY_SCROLL_LOCK_CLASS)) html.classList.remove(className);
  });
}

function enableBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.add(bodyScrollLockClassWithId);
}

function disableBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.remove(bodyScrollLockClassWithId);
}

function closeModal() {
  isShow.value = false;
  setTimeout(updateEmit, 200);
}
// watch
watch(
  () => props.show,
  (show) => {
    isShow.value = show;
  },
);

// set isShow before mount if no animation
if (props.noAnimationOnOpen) {
  onBeforeMount(() => {
    isShow.value = props.show;
  });
}
onMounted(() => {
  isShow.value = props.show;
  // Clear all body scroll lock classes before enable new one if this modal can be open only one
  if (props.singleModal) clearAllBodyScrollLock();
  enableBodyScrollLock();
});

onBeforeUnmount(() => {
  disableBodyScrollLock();
});
</script>

<template>
  <transition name="modal">
    <div
      v-if="isShow"
      v-bind="$attrs"
      class="BaseModal modal modal__backdrop"
      :class="[{
        'is--full': isFull,
        'is--small': isSmall,
        'is--large': isLarge,
        'is--no-header': !slots.title,
      }, $props.class]"
      @click.self="closeModal"
    >
      <div
        ref="modal"
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="modal__container"
        role="dialog"
        @keydown.esc="closeModal"
      >
        <div
          :class="[{'modal__header-solid': solidHeader},'modal__header']"
        >
          <h3
            v-if="slots.title"
            class="modal__header-title"
          >
            <slot name="title" />
          </h3>
          <button
            v-if="!hideCloseButton"
            aria-label="Close modal"
            class="modal__close-button"
            type="button"
            @click="closeModal"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              alt="close-icon"
              class="svg-icon modal__close-button-icon"
              v-html="require(`!!raw-loader!images/svg-icons/fa-close.svg`).default"
            />
          </button>
        </div>
        <div class="modal__content">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="sass" scoped>
@import '~Styles/_variables'
.modal
  --modal-title--background-color: #{$gray-90}
  --modal-content-small--padding: 0 24px 24px
  position: relative
  width: 50%
  height: 50%
  background-color: $white
  overflow: hidden
  text-align: left
  pointer-events: auto

  .modal__content
      padding: 24px
      padding-top: 0
  &.is--full
    width: 100%
    height: 100%
    padding: 0

    .modal__container
      width: 100%
      max-width: 100%
      height: 100%
      padding-top: 0
      animation: fade-in-up 0.3s
      background-color: $gray-10
      .modal__content
        padding: 60px 40px 110px
        @media screen and (max-width: 767px)
          padding: 45px 12px 100px
    &.modal-leave-active
      .modal__container
        animation: fade-in-out 0.3s
  &.is--small
    .modal__container
      max-width: 500px
      @media screen and (max-width: 767px)
        border-bottom-right-radius: 0
        border-bottom-left-radius: 0

    .modal__content
      padding: var(--modal-content-small--padding)

  &.is--large,
  &.is--small
    display: flex
    align-items: center
    justify-content: center
    @media screen and (max-width: 767px)
      align-items: flex-end
    &.modal-leave-active
      .modal__container
        animation: scale-out-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both
        @media screen and (max-width: 767px)
          animation: fade-in-out 0.3s
    .modal__container
      animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)
      @media screen and (max-width: 767px)
        animation: fade-in-up 0.3s
      .modal__header-solid
        margin-bottom: 24px
    .modal__content
      scrollbar-width: none
      -ms-overflow-style: none

      &::-webkit-scrollbar
        display: none
  &.is--no-header
    .modal__close-button-icon
      :deep(g)
        fill: $gray-90
  &__backdrop
    position: fixed
    z-index: 100
    overflow-y: auto
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba($gray-100, .5)
    backdrop-filter: blur(6.7957px)

  &__container
    flex-direction: column
    height: auto
    overflow: hidden
    position: relative
    z-index: 999
    background-color: $white
    max-width: 946px
    width: 100%

  &__header
    padding: 24px 24px 16px
    text-align: center
    position: relative
    top: 0
    left: 0
    right: 0
    z-index: 2
    width: 100%
    display: flex
    align-items: center
  &__header-title
    margin-bottom: 0
    flex: 1
    + .modal__close-button
      position: absolute
      right: 30px
  &__header-solid
    background-color: var(--modal-title--background-color)
    box-shadow: 0 1px 15px 0 rgb(0 0 0 / 5%)
    padding: 14px 30px
    @media screen and (max-width: 767px)
      padding: 14px 12px
    & .modal__close-button
      align-self: center
      @media screen and (max-width: 767px)
        right: 12px
      & .modal__close-button-icon
      :deep(g)
        fill: $white
    & .modal__header-title
      color: $white
      margin: 0 30px
  &__content
    overflow-y: scroll
    overflow-x: hidden
    height: 100%
    margin: 0 auto

  &__close-button
    cursor: pointer
    margin-left: auto
    align-self: flex-start

  &__close-button-icon,
    :deep(svg)
      width: 20px
      height: 20px

.modal-enter-active
  transition: all .3s ease

.modal-leave-active
  transition: all 0.2s ease

@keyframes scale-in-center
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes scale-out-center
  0%
    transform: scale(1)
  100%
    transform: scale(0)
@keyframes fade-in-up
  0%
    transform: translateY(100%)
  100%
    transform: translateY(0%)
@keyframes fade-in-out
  0%
    transform: translateY(0%)
  100%
    transform: translateY(100%)
</style>

<style lang="sass">
// body scroll lock
html[class*='is--modal-open']
  overflow: hidden
  pointer-events: none

  body
    scrollbar-width: none
    -ms-overflow-style: none

    &::-webkit-scrollbar
      display: none
</style>
