export interface IStorageData {
  [index: string]: string | boolean | null | Date;
}

function getNormalizedStorageValue(value: string) {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
}

/**
 * Set values to localStorage by keys
 * @param values - object with values to set
 * @param keys - keys to set, if not set - all keys from values will be used
 * @param definedOnly - if true, truth values will be set and falsy values will be removed. If false, all values will be set.
 */
export function setItems(values: IStorageData, keys = Object.keys(values), definedOnly: boolean) {
  keys.forEach((key) => {
    const value = values[key];
    if (value) {
      localStorage.setItem(key, String(value));
    } else if (definedOnly) {
      localStorage.removeItem(key);
    }
  });
}

/**
 * Get values from localStorage by keys
 * @param keys - keys to get
 */
export function getItems(keys: string[]): IStorageData {
  return keys.reduce((acc, key) => {
    const value = localStorage.getItem(key);
    if (value) {
      acc[key] = getNormalizedStorageValue(value);
    }
    return acc;
  }, {} as IStorageData);
}

/**
 * Remove values from localStorage by keys
 * @param keys
 */
export function removeItems(keys: string[]) {
  return keys.forEach(key => localStorage.removeItem(key));
}
