import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/company/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "PagePrivacyPolicy" */ '../PagePrivacyPolicy.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('privacy');
    },
  },
];
