import { ref, readonly, Ref } from 'vue';
import CONFIG from 'Config';
import { IFarmItem, IFarmListServerResponse } from 'Models';

const farmList: Ref<IFarmItem[]> = ref([]);
let farmListPromise: null | Promise<IFarmListServerResponse> = null;

export function useFarmList() {
  async function getFarmList() {
    if (farmListPromise) return farmListPromise;
    farmListPromise = fetch(`${CONFIG.urls.farm}/`)
      .then(response => response.json());
    const data = await farmListPromise;
    farmList.value = data.data;
    return data;
  }

  return {
    farmList: readonly(farmList),
    getFarmList,
  };
}
