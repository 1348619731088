import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/company/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "PageTermsOfUse" */ '../PageTermsOfUse.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('terms');
    },
  },
];
