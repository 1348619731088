const userAgent: string = navigator.userAgent || navigator.vendor;

export interface IDeviceBrowser {
  isSafari: boolean
}

const isChrome = Boolean([/Chrome/i].some(b => userAgent.match(b)));
const isSafari = Boolean([/Safari/i].some(b => userAgent.match(b)) && !isChrome);

export function useDeviceBrowser(): IDeviceBrowser {
  return {
    isSafari,
  };
}
