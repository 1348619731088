import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/investors',
    name: 'investors',
    component: () => import(/* webpackChunkName: "PageInvestors" */ '../PageInvestors.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('investors');
    },
  },
];
