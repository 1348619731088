export const AUTH_ERRORS = [
  {
    code: ['auth/user-disabled'],
    text: 'User has been disabled.',
  }, {
    code: ['auth/user-disabled'],
    text: 'User has been disabled.',
  }, {
    code: ['user-not-found', 'auth/user-not-found', 'auth/wrong-password', 'auth/invalid-email', 'access_denied'],
    text: 'Email or password is incorrect. Please use <a href="/reset-password">Reset Password</a> form to set up new password. Or contact us for assistance <a href="/company/contact-us">here</a>.',
  }, {
    code: ['auth/email-already-in-use', 'user_exists'],
    text: 'Email already in use, please <a class="small-link" href="/sign-in">log in</a>.',
  }, {
    code: ['auth/operation-not-allowed'],
    text: 'Operation is not allowed.',
  }, {
    code: ['auth/weak-password'],
    text: 'Password must be a minimum of eight characters.',
  }, {
    code: ['auth/requires-recent-login'],
    text: 'For operation completing, please log out and then log in.',
  }, {
    code: ['auth/too-many-requests', 'too_many_attempts'],
    text: 'Too many unsuccessful login attempts. Please try again later.',
  }, {
    code: ['blocked_user', 'auth/blocked'],
    text: 'Your account was temporary blocked because of suspicious activity. Please <a class="link-2 small-link" href="/company/contact-us">contact us</a> for details.',
  }, {
    code: ['reject-signup-by-country'],
    text: 'Sorry, we currently do not support signups from your country.',
  }, {
    code: ['password_leaked'],
    text: 'This login attempt has been blocked because the password you\'re using was previously disclosed through a data breach (not in this application). You must change your password in order to proceed. Please check your email for details.',
  },
];
