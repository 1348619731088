import { IRequestErrorServerResponse } from './RequestErrorServerResponse.interface';

export class RequestError extends Error {
  data?: IRequestErrorServerResponse | string

  response: Response
  handled: boolean

  constructor(message: string, response: Response, data?: unknown) {
    super(message);
    this.response = response;
    this.data = data as IRequestErrorServerResponse | string;
    this.handled = false;
  }
}
