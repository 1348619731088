import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/advisors',
    name: 'advisors',
    component: () => import(/* webpackChunkName: "PageForAdvisors" */ '../PageForAdvisors.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('advisors');
    },
  },
];
