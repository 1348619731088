import CONFIG from 'Config';
import { IPageData } from 'Models';

interface ITagItem {
  content: string,
  name?: string,
  property?: string,
}

function setMetaContent(key: string, meta: ITagItem) {
  const element: HTMLElement | null = document.head.querySelector(`meta[${key}="${meta.name || meta.property}"]`);
  if (element instanceof HTMLMetaElement) {
    element.content = meta.content;
  }
}

function setTitle(pageData: IPageData) {
  const newTitle = pageData?.seo_title || `${CONFIG.seo.defaultTitle}`;
  document.title = `${newTitle} | AcreTrader`;
  const ogTitle = { property: 'og:title', content: document.title };

  setMetaContent('property', ogTitle);
}

function setOgImage(pageData: IPageData) {
  const url = pageData?.thumbnail?.coverage?.url || `${CONFIG.seo.defaultImage}`;
  const ogImage = { property: 'og:image', content: url };

  setMetaContent('property', ogImage);
}

function setUrl(pageData: IPageData) {
  const path = pageData?.canonical || `${CONFIG.urls.frontend}${window.location.pathname}`;
  const ogUrl = { property: 'og:url', content: path };
  // set canonical url
  let link: HTMLLinkElement | null = document.head.querySelector('link[rel="canonical"]');
  if (!link) {
    link = document.createElement('link');
    if (link instanceof HTMLLinkElement) {
      link = document.createElement('link');
      link.rel = 'canonical';
      document.head.appendChild(link);
    }
  }
  link.href = path;
  // set og:url
  setMetaContent('property', ogUrl);
}

function setMetaTags(pageData: IPageData) {
  const seoTags = [
    {
      name: 'description',
      content: pageData?.seo_description || CONFIG.seo.defaultDesc,
    },
    {
      property: 'og:description',
      content: pageData?.seo_description || CONFIG.seo.defaultDesc,
    },
  ];
  seoTags.forEach((singleTag: ITagItem) => {
    Object.keys(singleTag).forEach((key: string) => {
      setMetaContent(key, singleTag);
    });
  });
}

function setMetaData(pageData: IPageData) {
  setTitle(pageData);
  setMetaTags(pageData);
  setUrl(pageData);
  setOgImage(pageData);
}

export default setMetaData;
