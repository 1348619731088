export function isObject(value: unknown): boolean {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isString(value: unknown): boolean {
  return typeof value === 'string' || value instanceof String;
}

export function wait(time = 0): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

// Generate id from random numbers and letters
export function generateIdShort(): string {
  return Math.random().toString(36).slice(2);
}

export function generateId(): string {
  return generateIdShort() + generateIdShort();
}

export function isNil(value: unknown): boolean {
  return value === undefined || value === null;
}

export function sortBy<T extends object>(collection: T[], param: string): T[] {
  collection.sort((a, b) => {
    if (!isObject(a) || !isObject(b)) throw new Error('sortBy expect array of objects as argument');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (a[param] < b[param]) return -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (a[param] > b[param]) return 1;
    return 0;
  });
  return collection;
}

export function getLastArrayElement(arr: unknown[]) {
  if (Array.isArray(arr) && !arr.length) throw new Error('getLastArrayElement expect array as argument');
  return arr[arr.length - 1];
}

/**
 * Filters properties from the first object based on the existence of properties in the second object.
 *
 * @template T - The type of the first object.
 * @template U - The type of the second object.
 * @param {T} obj1 - The first object containing properties to be filtered.
 * @param {U} obj2 - The second object used for filtering.
 * @returns {Partial<T>} - A new object with properties from the first object that only exist in the second object.
 */
export function pickObjectPropertiesByObject<T extends Record<string, unknown>, U extends Record<string, unknown>>(
  obj1: T,
  obj2: U,
): Partial<T> {
  const result: Partial<T> = {};

  Object.keys(obj1).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      const value1 = obj1[key as keyof T];
      const value2 = obj2[key as keyof U];

      if (typeof value1 === 'object' && typeof value2 === 'object') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[key as keyof T] = filterProperties(value1 as Record<string, unknown>, value2 as Record<string, unknown>);
      } else {
        result[key as keyof T] = value1;
      }
    }
  });

  return result;
}
