import { computed, reactive } from 'vue';
import { Auth, ILoginData, AuthError } from '@/services/auth';

function getInitialLoginData(): ILoginData {
  return {
    token: null,
    isEmailVerified: false,
    expirationTime: null,
    mfa: false,
    email: '',
    isFirstLogin: false,
  };
}

const loginData = reactive(getInitialLoginData());

export function useLogin() {
  function setLoginData(newLoginData: ILoginData): void {
    Object.assign(loginData, newLoginData);
  }

  function resetLoginData() {
    setLoginData(getInitialLoginData());
  }

  async function initAuth() {
    try {
      const initialLoginData = await Auth.init();
      setLoginData(initialLoginData);
      return initialLoginData;
    } catch (error) {
      if (!(error instanceof AuthError)) throw error;
      return null;
    }
  }

  async function logout() {
    resetLoginData();
    await Auth.logout();
  }

  const isLoggedIn = computed(() => Boolean(loginData.token));
  const isEmailVerified = computed(() => !isLoggedIn.value || loginData.isEmailVerified);

  return {
    token: loginData.token,
    isLoggedIn,
    isEmailVerified,
    initAuth,
    setLoginData,
    logout,
  };
}
