export function getUrlParamsByObject(obj: Record<string, string|number|undefined>): string {
  const params: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) params.push(`${key}=${value}`);
  });
  return params.length ? `?${params.join('&')}` : '';
}

export function getParsedUrlParamsBySearch(searchString: string): Record<string, string> {
  if (!searchString) return {};
  let query = searchString;
  if (query.startsWith('?')) query = query.substring(1);
  const pairs = query.split('&');
  if (!pairs || !pairs.length) return {};
  const result: Record<string, string> = {};
  pairs.forEach((param) => {
    const pair = param.split('=');
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

export function getSearchParamBySearch(paramName: string, searchString: string): string {
  const params = getParsedUrlParamsBySearch(searchString);
  return params[paramName];
}
