import { computed } from 'vue';
import { useUserProfile } from './userProfile';

const { profileData } = useUserProfile();
const userFirstName = computed(() => profileData.value.first_name);
const userLastName = computed(() => profileData.value.last_name);
const userFullName = computed(() => `${userFirstName.value} ${userLastName.value}`);

export function useUserNames() {
  return {
    userFirstName,
    userLastName,
    userFullName,
  };
}
