import { ref, readonly, Ref } from 'vue';
import { fetchFarmListOpenedSortedByWeight } from 'Api';
import { IFarmItem } from 'Models';

const farmListOpenedSorted: Ref<IFarmItem[]> = ref([]);

export function useFarmListOpenedSorted() {
  async function getFarmListOpenedSorted() {
    const data = await fetchFarmListOpenedSortedByWeight();
    farmListOpenedSorted.value = data.data;
    return data;
  }

  return {
    farmListOpenedSorted: readonly(farmListOpenedSorted),
    getFarmListOpenedSorted,
  };
}
