import { computed } from 'vue';
import { useNotificationData } from './notificationData';

export function useNotificationDataAll() {
  const {
    notificationSystemList,
    notificationSystemNumber,
    notificationSystemNumberUnread,
    notificationUpdatesList,
    notificationUpdateNumber,
    notificationUpdateNumberUnread,
  } = useNotificationData();

  const notificationAllList = computed(() => {
    const items = notificationSystemList.value.concat(notificationUpdatesList.value);
    items.sort((a, b) => Date.parse(b.time) - Date.parse(a.time));
    return items;
  });
  const notificationAllNumber = computed(() => notificationSystemNumber.value + notificationUpdateNumber.value);
  const notificationAllNumberUnread = computed(() => (
    notificationSystemNumberUnread.value + notificationUpdateNumberUnread.value
  ));

  const isSomeNotificationWithRedFlag = computed(() => (
    notificationAllList.value.some(item => item.message.flag === 'red' && item.read_flag === false)
  ));

  return {
    notificationAllList,
    notificationAllNumber,
    notificationAllNumberUnread,
    isSomeNotificationWithRedFlag,
  };
}
