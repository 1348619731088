import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/company/our-impact',
    name: 'our-impact',
    component: () => import(/* webpackChunkName: "PageSustainability" */ '../PageSustainability.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('our-impact');
    },
    children: [
      {
        path: ':slug',
        name: 'our-impact-child',
        component: () => import(/* webpackChunkName: "PageSustainability" */ '../PageSustainability.vue'),
        meta: {
          skipScrollTab: true,
        },
      },
    ],
  },
];
