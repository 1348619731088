import Auth0, { Auth0DecodedHash, Auth0Error, AuthorizeOptions } from 'auth0-js';
import CONFIG from 'Config';
import { ILoginData } from '@/services/auth/types/LoginData.interface';

const IS_MOBILE = navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i);

interface IAuth0PopupAuthorizeOptions extends AuthorizeOptions {
  domain: string;
  redirectUri: string;
  responseType: string;
  responseMode?: 'query' | 'fragment' | 'form_post';
  owp?: boolean;
}

const webAuth = new Auth0.WebAuth({
  ...CONFIG.auth0Config,
});

export default class AuthAuth0 {
  webAuth: Auth0.WebAuth;

  constructor() {
    this.webAuth = webAuth;
  }

  _getLoginDataByResult(authResult: Auth0DecodedHash): ILoginData {
    return {
      token: `Auth0 ${authResult.idToken}`,
      expirationTime: new Date(authResult.idTokenPayload.exp * 1000), // converting sec to ms
      mfa: authResult.idTokenPayload[`${CONFIG.auth0Namespace}use_mfa`],
      email: authResult.idTokenPayload[`${CONFIG.auth0Namespace}email`],
      isFirstLogin: Boolean(authResult.idTokenPayload[`${CONFIG.auth0Namespace}first_login`]),
      isEmailVerified: Boolean(
        authResult.idTokenPayload[`${CONFIG.auth0Namespace}emailVerified`]
        || authResult.idTokenPayload[`${CONFIG.auth0Namespace}email_verified`],
      ),
    };
  }

  _authorizeReload(config: AuthorizeOptions) {
    webAuth.authorize({ ...config });
    // TODO: avoid unresolved promise
    return new Promise(() => { /* unresolved promise */ });
  }

  _authorizePopup(config: AuthorizeOptions): Promise<Auth0.Auth0Result> {
    return new Promise((resolve, reject) => {
      webAuth.popup.authorize(config as IAuth0PopupAuthorizeOptions, (err, authResult) => {
        if (err) {
          // Ignoring syntax errors
          if (err.name !== 'SyntaxError') reject(err);
        } else {
          resolve(authResult);
        }
      });
    });
  }

  _getRedirectUrlForReload(additionQuery = '') {
    const {
      origin,
      search = '',
      pathname,
    } = window.location;
    const isAuthPage = ['/sign-in', '/sign-up', '/sign-up-social'].includes(pathname);
    const query = search.startsWith('?') ? search.substring(1) : search;
    let queryArray = query.split('&');
    if (additionQuery) {
      // Filter additional query to do not duplicate
      const additionalQueryArray = additionQuery.split('&');
      const additionalQueryFiltered = additionalQueryArray.filter(item => !queryArray.includes(item));
      queryArray = queryArray.concat(additionalQueryFiltered);
    }
    if (isAuthPage) return `${origin}${pathname}?${queryArray.join('&')}`;
    // Return to sign up if its signup process
    const redirectPage = pathname.startsWith('/sign-up') ? 'sign-up' : 'sign-in';
    // If there is redirect query or it's signup process
    if (search.includes('redirect=') || pathname.startsWith('/sign-up')) {
      // return with exist redirect or w/o it
      return `${origin}/${redirectPage}?${queryArray.join('&')}`;
    }
    queryArray.push(`redirect=${pathname}`);
    return `${origin}/${redirectPage}?${queryArray.join('&')}`;
  }

  _onSuccess(authResult: Auth0DecodedHash): { loginData: ILoginData } {
    return { loginData: this._getLoginDataByResult(authResult) };
  }

  _onSocialLoginSuccess(authResult: Auth0.Auth0Result) {
    return this._getLoginDataByResult(authResult);
  }

  _login(email: string, password: string, realm = CONFIG.auth0DB): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      webAuth.login({
        email,
        password,
        realm,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        popup: true, // no types but needed
      }, (err, authResult: Auth0DecodedHash) => {
        if (!err) {
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }

  async socialLoginPopup(options: AuthorizeOptions): Promise<ILoginData> {
    const authResult = await this._authorizePopup({ ...options });
    return this._onSocialLoginSuccess(authResult);
  }

  async socialLoginReload(options: AuthorizeOptions) {
    const redirectUri = this._getRedirectUrlForReload('social=true');
    await this._authorizeReload({
      ...options,
      redirectUri,
    });
    return {} as ILoginData;
  }

  async socialLogin(connection: string, options = {}): Promise<ILoginData> {
    const requestOptions = {
      ...options,
      connection,
    };
    return IS_MOBILE
      ? this.socialLoginReload(requestOptions)
      : this.socialLoginPopup(requestOptions);
  }

  async login(email: string, password: string): Promise<{ loginData: ILoginData }> {
    try {
      const authResult = await this._login(email, password);
      return this._onSuccess(authResult);
    } catch (e) {
      if ((e as Auth0Error).description === 'Multifactor authentication required') {
        const redirectUri = this._getRedirectUrlForReload();
        await this._authorizeReload({ redirectUri });
      }
      throw e;
    }
  }

  logout(): Promise<boolean> {
    return new Promise(((resolve) => {
      const iframe = document.createElement('iframe');
      iframe.src = webAuth.client.buildLogoutUrl({
        returnTo: CONFIG.auth0Config.redirectUri,
        clientID: CONFIG.auth0Config.clientID,
      });
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.addEventListener('load', () => {
        setTimeout(() => document.body.removeChild(iframe), 2000);
        resolve(true);
      });
    }));
  }
}
