import CONFIG from 'Config';
// eslint-disable-next-line import/no-cycle
import { IInvestmentListItem, IInvestmentListSummary } from 'Models';
import { request } from '../request';
import { IListResponseData } from '../types';

export function fetchInvestmentListClosed(): Promise<IListResponseData<IInvestmentListItem>> {
  return request<IListResponseData<IInvestmentListItem>>(`${CONFIG.urls.investment}/?state=active&order=closed:desc`);
}

export function fetchInvestmentListSummary(): Promise<IInvestmentListSummary> {
  return request<IInvestmentListSummary>(`${CONFIG.urls.investment}/summary`);
}
