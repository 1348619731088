import { AuthError } from './AuthError';
import { IAuthLoginErrorMessage } from './types';

export class AuthSocialError extends AuthError {
  provider: string;

  constructor(error: string | IAuthLoginErrorMessage, provider: string) {
    super((typeof error === 'string')
      ? error
      : AuthSocialError.getAuthErrorMessage(error, provider));
    this.provider = provider;
    this.name = 'SocialLoginAuthError';
  }

  static SOCIAL_NAME_MAP: Record<string, string> = {
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
    'google-oauth2': 'Google',
  };

  static getSocialName(provider: string): string {
    return AuthSocialError.SOCIAL_NAME_MAP[provider] || 'social';
  }

  static getAuthErrorMessage(error: IAuthLoginErrorMessage, provider?: string): string {
    if (error.original === 'User closed the popup window') return '';
    if ((error.description || error.errorDescription) === 'user is blocked') {
      return AuthError.getAuthErrorByCode('blocked_user');
    }
    const errorText = error.description
      || error.errorDescription
      || AuthError.getAuthErrorMessage(error);
    if (errorText === 'social_mfa_required') {
      return `
          Since you have enabled two factor authentication, as an additional security step,
          please log in with ${AuthSocialError.getSocialName(provider || '')} once again. Thank you!
        `;
    }
    return errorText;
  }
}
