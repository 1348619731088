import CONFIG from 'Config/index';
import { request } from '@/services/api/request';
import BaseWSClient from './BaseWSClient';

export default class AuthWSClient extends BaseWSClient {
  static async getTemporaryToken(): Promise<string> {
    const data = await request<{ token: string }>(`${CONFIG.urls.user}/temporary_token`);
    return data.token;
  }

  async _connect(): Promise<WebSocket> {
    const token = await AuthWSClient.getTemporaryToken();
    this.client = new WebSocket(`${CONFIG.urls.notificationWS}?token=${token}`);
    return Promise.resolve(this.client);
  }
}
