import { RouteLocation } from 'vue-router';
import { useLogin } from 'Store';
import CONFIG from 'Config';

import { openModalOptOutConfirmed } from '@/components/ModalOptOutConfirmed';
import { TrackingOptOutCookie } from 'Const/cookies';

import { suppressUser } from '@/services/api/user/user';
import { getUserProfileParams, getUniversalParams } from './params';
import {
  LINK_CLICKED,
  BEGIN_INVESTING,
  GET_STARTED,
  EXPLAINER_WATCH_END,
  EXPLAINER_WATCH_START,
  ADVISOR_REQUEST_INTRODUCTION,
  ADVISOR_SCHEDULE_CALL,
  VIEWED_REALIZED_TRACK_RECORD,
} from './events';

export function triggerEvent(event: string, data = {}) {
  const baseParams = getUniversalParams();
  window.analytics?.track(event, { ...data, ...baseParams });
}

export function triggerPage(event: string) {
  const baseParams = getUniversalParams();
  window.analytics?.page(event, { ...baseParams });
}

export function triggerExplainerWatchStart(): void {
  triggerEvent(EXPLAINER_WATCH_START);
}

export function triggerExplainerWatchEnd(data: { [key: string]: string | number | boolean }): void {
  triggerEvent(EXPLAINER_WATCH_END, data);
}

export function triggerGetStarted(email: string): void {
  triggerEvent(GET_STARTED, { email });
}

export function triggerBeginInvesting(): void {
  triggerEvent(BEGIN_INVESTING);
}

export function triggerLinkClicked(data = {}): void {
  triggerEvent(LINK_CLICKED, data);
}

export function triggerAdvisorButtonClick(data: { [key: string]: unknown }): void {
  triggerEvent(ADVISOR_REQUEST_INTRODUCTION, data);
}

export function triggerAdvisorScheduleCall(): void {
  triggerEvent(ADVISOR_SCHEDULE_CALL);
}

export function triggerViewedRealizedTrackRecord(): void {
  triggerEvent(VIEWED_REALIZED_TRACK_RECORD, getUserProfileParams());
}

function setNoTrackingCookie() {
  document.cookie = `${TrackingOptOutCookie}=1; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
}

function markUserAsOptedOut() {
  // TODO: Ethan Zitting - Implement this.
  //       Mark in the database that the user has opted out so that we can more consistently respect their choice for all tracking methods.
}

export async function handleOptOut(): Promise<void> {
  setNoTrackingCookie();

  const { isLoggedIn } = useLogin();

  if (isLoggedIn.value) {
    markUserAsOptedOut();
    suppressUser(); // In Segment
  }

  const message = isLoggedIn.value
    ? 'The page will now reload to finish your request to opt out of sharing your tracking information with third parties for advertising purposes.'
    : 'The page will now reload to finish your request to opt this browser out of sharing your tracking information with third parties for advertising purposes.';
  openModalOptOutConfirmed({ message });
}

function triggerSiftPageView(): void {
  const _sift = window._sift = window._sift || []; // eslint-disable-line
  _sift.push(['_setAccount', CONFIG.siftAccount]);
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _sift.push(['_setUserId', email]);
  // }
  _sift.push(['_trackPageview']);
}

function triggerHSPageView(route: RouteLocation): void {
  if (document.cookie.includes(TrackingOptOutCookie)) return;

  const _hsq = window._hsq = window._hsq || []; // eslint-disable-line
  // if (store.getters['login/isLoggedIn']) {
  //   const { email } = store.getters['profile/profile'];
  //   _hsq.push(['identify', { email }]);
  // } else {
  _hsq.push(['identify']);
  // }
  _hsq.push(['setPath', route.fullPath]);
  _hsq.push(['trackPageView']);
}

export function onPageResolved(route: RouteLocation): void {
  setTimeout(() => {
    triggerPage(route.path);
    triggerSiftPageView();
    triggerHSPageView(route);
  }, 1500);
}
