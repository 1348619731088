import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/resources/how-it-works',
    name: 'how-it-works',
    component: () => import(/* webpackChunkName: "PageHowItWorks" */ '../PageHowItWorks.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('how-it-works');
    },
  },
];
