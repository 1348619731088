import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

export async function resolveRouteDependencies(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  const promises: Array<Promise<void>> = [];
  to.matched.forEach((entry) => {
    if (typeof entry.meta.dependence === 'function') {
      promises.push(entry.meta.dependence());
    }
  });
  await Promise.all(promises);
  next();
}
