import { ref, shallowReadonly, Ref } from 'vue';
import { getUserProfile } from '@/services/api/user';
import { IUserProfileData } from 'Models';

function getInitialUserProfileData(): IUserProfileData {
  return {
    first_name: '', // eslint-disable-line camelcase
    last_name: '', // eslint-disable-line camelcase
    phone: '',
    email: '',
  };
}

const userProfile: Ref<IUserProfileData> = ref(getInitialUserProfileData());

export function useUserProfile() {
  async function getProfileData(): Promise<IUserProfileData> {
    const data = await getUserProfile();
    userProfile.value = data;
    return data;
  }

  async function resetProfileData() {
    userProfile.value = getInitialUserProfileData();
  }

  return {
    getProfileData,
    resetProfileData,
    profileData: shallowReadonly(userProfile),
  };
}
