import { INotificationItem } from 'Models';

export class NotificationItemClass implements INotificationItem {
  message;

  time: string;

  channel: string;

  // eslint-disable-next-line camelcase
  read_flag;

  // eslint-disable-next-line camelcase
  user_id;

  constructor(data: INotificationItem) {
    this.message = data.message;
    this.time = data.time;
    this.channel = data.channel;
    // eslint-disable-next-line camelcase
    this.read_flag = data.read_flag;
    // eslint-disable-next-line camelcase
    this.user_id = data.user_id;
    Object.entries(data).forEach(([key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[key] = value;
    });
  }

  static getIconFileNameByType(type: string, code: string): string {
    switch (type) {
      case 'welcome':
        return 'waving-hand-sign';
      case 'verification':
        return 'bust-in-silhouette';
      case 'congrats':
        return 'party-popper';
      case 'document':
        if (code === 'farm_update_doc') return 'ear-of-rice';
        return 'page-facing-up';
      case 'wallet':
        return 'money-bag';
      case 'investment':
        return 'banknote-with-dollar-sign';
      case 'distribution':
        return 'money-with-wings';
      case 'custom_distribution':
        return 'custom-distribution';
      case 'fountain-pen':
        return 'fountain-pen';
      case 'restricted':
        return 'no-entry';
      default:
        return type;
    }
  }

  get iconSrc() {
    const iconName = NotificationItemClass
      .getIconFileNameByType(String(this.message?.type), String(this.message?.code));
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`images/icon/png-emoji/${iconName}.png`);
  }
}
