import { RouteRecordRaw } from 'vue-router';
import sustainability from 'Pages/Sustainability/route/sustainability';
import howItWorks from 'Pages/HowItWorks/route/how-it-works';
import aboutUs from 'Pages/AboutUs/route/about-us';
import maintenance from 'Pages/Maintenance/route/maintenance';
import home from 'Pages/Home/route/home';
import privacy from 'Pages/PrivacyPolicy/route/privacy';
import terms from 'Pages/TermsOfUse/route/terms';
import advisors from 'Pages/ForAdvisors/route/for-advisors';
import investors from 'Pages/Investors/route/investors';

const routes: RouteRecordRaw[] = [
  ...home,
  ...aboutUs,
  ...sustainability,
  ...howItWorks,
  ...maintenance,
  ...privacy,
  ...terms,
  ...advisors,
  ...investors,
];

export default routes;
