import CONFIG from 'Config';
// eslint-disable-next-line import/no-cycle
import { IUserProfileData } from 'Models';
import { request } from '../request';

export function getUserProfile(): Promise<IUserProfileData> {
  return request<IUserProfileData>(`${CONFIG.urls.user}/profile`);
}

export function postUserLoginFail(data: Record<string, string>): Promise<void> {
  return request<void>(`${CONFIG.urls.user}/login-fail`, 'POST', data);
}

/**
 * Mark the user as suppressed in Segment, blocking all tracking for them.
 */
export function suppressUser(): void {
  request<void>(`${CONFIG.urls.user}/suppression`, 'POST');
}
