import { ref, readonly } from 'vue';

const screenWidth = ref(10000);
const screenHeight = ref(0);

export function useScreenSize() {
  function setScreenWidth(screenWidthValue: number) {
    screenWidth.value = screenWidthValue;
  }

  function setScreenHeight(screenWidthValue: number) {
    screenWidth.value = screenWidthValue;
  }

  return {
    screenWidth: readonly(screenWidth),
    screenHeight: readonly(screenHeight),
    setScreenWidth,
    setScreenHeight,
  };
}
