import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "PageMaintenance" */ '../PageMaintenance.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('maintenance');
    },
  },
];
