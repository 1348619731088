import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/',
    name: 'home',
    alias: ['/mwf', '/meb', '/explainer', '/sb'],
    component: () => import(/* webpackChunkName: "PageHome" */ 'Pages/Home/PageHome.vue'),
    meta: {
      dependence: async () => {
        const { useFarmListOpenedSorted } = await import('Store/farm/farmListOpenedSorted');
        const { getFarmListOpenedSorted } = useFarmListOpenedSorted();
        await getFarmListOpenedSorted();
      },
    },
    async beforeEnter() {
      await fetchAndSetPageData('home');
    },
  },
];
