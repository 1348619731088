import { ref, computed, shallowReadonly, Ref } from 'vue';
import { fetchInvestmentProfileAccreditationIssueList } from '@/services/api/investment';
import { IInvestmentProfileAccreditationIssue } from 'Models';

const investmentProfileAccreditationIssueList: Ref<IInvestmentProfileAccreditationIssue[]> = ref([]);
const isInvestmentProfileAccreditationIssue = computed(() => (
  Boolean(investmentProfileAccreditationIssueList.value.length)
));

export function useInvestmentProfileAccreditationIssue() {
  async function getInvestmentProfileAccreditationIssueList() {
    investmentProfileAccreditationIssueList.value = await fetchInvestmentProfileAccreditationIssueList();
  }

  return {
    getInvestmentProfileAccreditationIssueList,
    investmentProfileAccreditationIssueList: shallowReadonly(investmentProfileAccreditationIssueList),
    isInvestmentProfileAccreditationIssue,
  };
}
