import { getItems, setItems, removeItems } from 'Utils/storage';
import { IAuthLoginErrorMessage, ILoginData } from './types';
import { AuthError } from './AuthError';
import { AuthSocialError } from './AuthSocialError';
import AuthAuth0 from './AuthAuth0';

export class Auth {
  loginData: ILoginData | null;

  _auth: AuthAuth0;

  static LOGIN_DATA_STORAGE_KEYS = ['mfa', 'expirationTime', 'token', 'isEmailVerified'];

  constructor() {
    this.loginData = null;
    this._auth = new AuthAuth0();
  }

  _getLoginDataFromStorage(): ILoginData {
    const loginData = getItems(Auth.LOGIN_DATA_STORAGE_KEYS);
    if (typeof loginData.expirationTime === 'string') {
      loginData.expirationTime = new Date(loginData.expirationTime);
    }
    return loginData as ILoginData;
  }

  _setLoginDataToStorage(loginData: ILoginData): void {
    setItems(loginData, Auth.LOGIN_DATA_STORAGE_KEYS, true);
  }

  _validateLoginData(loginData: ILoginData): boolean {
    return Boolean(loginData?.token)
      && loginData?.expirationTime instanceof Date
      && loginData.expirationTime > new Date();
  }

  init() {
    const storageLoginData = this._getLoginDataFromStorage();
    if (!this._validateLoginData(storageLoginData)) {
      throw new AuthError('Auth required');
    } else {
      this.loginData = storageLoginData;
      return this.loginData;
    }
  }

  get token(): ILoginData['token'] {
    return this.loginData?.token || null;
  }

  get isEmailVerified(): ILoginData['isEmailVerified'] {
    return this.loginData?.isEmailVerified || false;
  }

  setLoginData(loginData: ILoginData): void {
    this.loginData = loginData;
    this._setLoginDataToStorage(loginData);
  }

  removeLoginData(): void {
    this.loginData = null;
    removeItems(Auth.LOGIN_DATA_STORAGE_KEYS);
  }

  onSuccessLogin(loginData: ILoginData): void {
    this.setLoginData(loginData);
    // await httpClientUser.get('/login');
  }

  async onSuccessLoginSocial(loginData: ILoginData) {
    await this.onSuccessLogin(loginData);
    return loginData;
  }

  async login(email: string, password: string): Promise<ILoginData> {
    try {
      const { loginData } = await this._auth.login(email, password);
      this.onSuccessLogin(loginData);
      return loginData;
    } catch (error) {
      throw new AuthError(error as IAuthLoginErrorMessage);
    }
  }

  async socialLogin(provider: string): Promise<ILoginData> {
    try {
      const loginData = await this._auth.socialLogin(provider);
      return this.onSuccessLoginSocial(loginData);
    } catch (error) {
      throw new AuthSocialError(error as IAuthLoginErrorMessage, provider);
    }
  }

  async logout(): Promise<void> {
    await this._auth.logout();
    this.removeLoginData();
  }
}
