import { computed, shallowReactive, shallowReadonly, ShallowReactive } from 'vue';
import { IInvestmentListSummary } from 'Models';
import { fetchInvestmentListSummary } from 'Api';

const investmentListSummary: ShallowReactive<IInvestmentListSummary> = shallowReactive({
  active: 0,
  exited: 0,
  funding: 0,
});

const isSomeInvestment = computed(() => (
  Boolean(investmentListSummary.active + investmentListSummary.exited + investmentListSummary.funding)
));

export function useInvestmentListSummary() {
  async function loadInvestmentListSummary() {
    const result = await fetchInvestmentListSummary();
    Object.assign(investmentListSummary, result);
    return investmentListSummary;
  }

  return {
    investmentListSummary: shallowReadonly(investmentListSummary),
    isSomeInvestment,
    loadInvestmentListSummary,
  };
}
