import { ref, shallowReadonly, ShallowRef, Component } from 'vue';
import { IModalProps, IModal } from './types';

const modalList: ShallowRef<IModal[]> = ref([]);

export function useModalStore() {
  function addModal(modalData: IModal) {
    modalList.value.push(modalData);
  }

  function removeModalByComponent(modalComponent: Component) {
    modalList.value = modalList.value.filter(modal => modal.component !== modalComponent);
  }

  function updateModalPropsByComponent(modalComponent: Component, props: IModalProps) {
    const modalData = modalList.value.find(modal => modal.component === modalComponent);
    if (modalData) {
      Object.assign(modalData.props, props);
    }
  }

  function clearAllModals() {
    modalList.value = [];
  }

  return {
    addModal,
    removeModalByComponent,
    updateModalPropsByComponent,
    clearAllModals,
    modalList: shallowReadonly(modalList),
  };
}
