import CONFIG from 'Config/index';
import { INotificationItem } from 'Models';

export default class BaseWSClient {
  channels: {
    name: string
    data: Record<string|number, unknown>
    handler: (message: INotificationItem) => void
  }[]

  closedManual: boolean

  initPromise?: Promise<boolean>

  reconnectTimeout: number

  reconnectTimeoutId?: number

  client?: WebSocket

  constructor() {
    this.channels = [];
    this.closedManual = false;
    this.reconnectTimeout = 2000;
  }

  get isOpen(): boolean {
    return this.client?.readyState === WebSocket.OPEN;
  }

  init(): Promise<boolean> {
    clearTimeout(this.reconnectTimeoutId);
    if (this.closedManual) return Promise.resolve(true);
    this.initPromise = new Promise((resolve) => {
      // There is problem to use async/await in Safari 10 here
      this._connect().then(() => {
        if (!this.client) {
          resolve(false);
          return;
        }
        this.client.onclose = this.onClose.bind(this);
        this.client.onmessage = this.onMessage.bind(this);
        this.client.onerror = this.onError.bind(this);
        this.client.onopen = () => {
          this.onOpen();
          resolve(true);
        };
      });
    });
    return this.initPromise;
  }

  open(): Promise<boolean> {
    return this.init();
  }

  // This need to be override
  async _connect(): Promise<WebSocket> {
    this.client = new WebSocket(CONFIG.urls.notificationWS);
    return Promise.resolve(this.client);
  }

  _reconnect(): void {
    clearTimeout(this.reconnectTimeoutId);
    if (this.isOpen) return;
    this.reconnectTimeoutId = setTimeout(this.init.bind(this), this.reconnectTimeout);
    this.reconnectTimeout += 5000;
  }

  async send(command: string, data: unknown): Promise<void> {
    await this.initPromise;
    if (!this.client) return;
    this.client.send(JSON.stringify({
      command,
      data,
    }));
  }

  _sendSubscribe(name: string, data: Record<string|number, unknown>): void {
    this.send('subscribe', {
      channels: [name],
      ...data,
    });
  }

  _sendUnsubscribe(name: string): void {
    this.send('unsubscribe', { channels: [name] });
  }

  async subscribe(name: string, data = {}, handler: (meesage: INotificationItem) => void): Promise<void> {
    // Avoid duplicated subscriptions
    if (this.channels.find(c => c.name === name)) return;
    this.channels.push({ name, data, handler });
    if (this.isOpen) await this._sendSubscribe(name, data);
  }

  async unsubscribe(name: string): Promise<string> {
    this.channels = this.channels.filter(c => c.name !== name);
    if (this.isOpen) await this._sendUnsubscribe(name);
    return name;
  }

  setMessageAsRead(messageId: string | number): void {
    this.send('mark_read', messageId);
  }

  setAllMessagesAsRead(options = {}): void {
    this.send('mark_all_read', options);
  }

  reset(): void {
    clearTimeout(this.reconnectTimeoutId);
    this.reconnectTimeout = 2000;
  }

  close(): void {
    this.closedManual = true;
    this.reset();
    if (this.client) this.client.close();
  }

  onOpen(): void {
    this.reconnectTimeout = 2000;
    this.closedManual = false;
    this.channels.forEach(({ name, data }) => this._sendSubscribe(name, data));
  }

  onMessage(messageText: MessageEvent): void {
    const message: INotificationItem = JSON.parse(messageText.data);
    const channel = this.channels.find(c => c.name === message.channel);
    if (channel && channel.handler) channel.handler(message);
  }

  onClose(): void {
    if (!this.closedManual) this._reconnect();
  }

  onError(): void {
    this._reconnect();
  }
}
