import { Ref, ref } from 'vue';
import { IMarketMessage } from 'Models';

const marketMessages: Ref<IMarketMessage[]> = ref([]);

export function useMarketMessages() {
  function addMarketMessage(message: IMarketMessage) {
    marketMessages.value = marketMessages.value.concat(message);
  }

  return {
    addMarketMessage,
    marketMessages,
  };
}
