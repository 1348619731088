const ERRORS = [
  {
    code: ['payments_provider_is_down'],
    text: 'An error occurred. Please <a target="_blank" href="/company/contact-us">contact us</a>.',
  },
  {
    code: ['contact_us'],
    text: `We're sorry, there appears to be an issue with your submission. If you have questions, please
       <a target="_blank" href="/company/contact-us">contact us</a>.`,
  },
  {
    code: ['suspended'],
    text: `Your entity investment account has been suspended. Please
      <a target="_blank" href="/company/contact-us">contact us</a>
      to solve an issue with your identity verification.`,
  },
  {
    code: ['not_suitability'],
    text: 'Please, first pass suitability form.',
  },
];

export function getServerErrorByCode(code: string) {
  if (!code) return '';
  const error = ERRORS.find(err => err.code.includes(code));
  return (error && error.text) || null;
}
