import { createApp } from 'vue';
import { useLogin } from 'Store';
import { useMaintenance } from '@/composables/maintenanceMode';
import AppComponent from './App.vue';
import router from './router';
import { setMonitorHandler } from './monitorHandler';

useLogin().initAuth();

const { checkHashForSuperUser } = useMaintenance();
checkHashForSuperUser();

async function createAndMount() {
  const app = createApp(AppComponent).use(router);
  if (process.env.NODE_ENV === 'production') {
    setMonitorHandler(app, router);
  }
  await router.isReady();
  app.mount('#app');
}

createAndMount();
