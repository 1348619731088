import {
  SERVER_GENERAL_ERROR_MESSAGE,
  SERVER_GENERAL_ERROR_MESSAGE2,
} from 'Config/general-error-message';
import { AUTH_ERRORS } from './const/auth-errors';
import { IAuthLoginErrorMessage } from './types';

export class AuthError extends Error {
  constructor(error: string | IAuthLoginErrorMessage) {
    super((typeof error === 'string') ? error : AuthError.getAuthErrorMessage(error));
    this.name = 'AuthError';
  }

  static getAuthErrorByCode(code: string) {
    const error = AUTH_ERRORS.find(err => err.code.includes(code));
    return error?.text || '';
  }

  static getAuthErrorMessage(error: IAuthLoginErrorMessage) {
    if (error === undefined) return SERVER_GENERAL_ERROR_MESSAGE;
    // Do not show error when user close tab with popup
    if (error.original === 'User closed the popup window') return '';
    // ignore code `access_denied` as error because it's general error code on reject after login
    // but still it's code for wrong password, so check if is the case by description text
    if (error.code
      && (error.code !== 'access_denied' || error.description === 'Wrong email or password.')) {
      const formattedError = AuthError.getAuthErrorByCode(error.code);
      if (formattedError) return formattedError;
    }
    if (error.friendly_message) return error.friendly_message;
    if (error.message) return error.message;
    if (typeof error.description === 'string') return error.description;
    if (typeof error.error === 'string') return error.error;
    return SERVER_GENERAL_ERROR_MESSAGE2;
  }
}
