import { Auth } from '@/services/auth';
import { useLogin } from '@/store/login/login';
import { RequestError } from './RequestError';

function _getBody(data: unknown) {
  if (!data) return undefined;
  if (data instanceof FormData) return data;
  return JSON.stringify(data);
}

function _getOptions(method: string, data?: unknown): RequestInit {
  const headers: Record<string, string> = { 'Content-Type': 'application/json;charset=UTF-8' };
  const { token } = Auth;
  if (token) headers.Authorization = token;
  return {
    method,
    body: data ? _getBody(data) : undefined,
    headers,
  };
}

function _parseJSON<ResponseDataType>(response: Response): Promise<ResponseDataType> {
  return response.text()
    .then((text) => {
      let result = {};
      try {
        result = JSON.parse(text);
      } catch (ignore) { } // eslint-disable-line
      return result as ResponseDataType;
    });
}

function _checkStatus(response: Response, data: unknown): void {
  if (response.ok) return;
  // TODO: refactor login on 401 and 403. Wrong place to do this
  if ([401, 403].includes(response.status)) {
    const { isLoggedIn, logout } = useLogin();
    if (isLoggedIn.value) logout();
  }
  throw new RequestError(response.statusText, response, data);
}

export async function request<ResponseDataType>(url: string, method = 'GET', data?: unknown): Promise<ResponseDataType> {
  const response = await fetch(url, _getOptions(method, data));
  const parsedData = await _parseJSON<ResponseDataType>(response);
  _checkStatus(response, parsedData);
  return parsedData;
}
