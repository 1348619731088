import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';

const isSentryEnabled = process.env.IS_SENTRY_ENABLED === 'true';

export function setMonitorHandler(app: App, router: Router) {
  if (!isSentryEnabled || window.__prerender) return;
  Sentry.init({
    app,
    dsn: 'https://81c3e6af46d341a18d5100d30a651d0f@o1180669.ingest.sentry.io/4505279294996480',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [process.env.FRONTEND_URL],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export function captureError(error: unknown) {
  if (!isSentryEnabled) return;
  Sentry.captureException(error);
}
