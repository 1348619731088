export const FINANCIAL_SITUATION = [
  'Net Worth',
  'Individual Income',
  'Joint Income',
  'Entity Accredited Investor',
  'Qualified Purchaser',
  'Qualified Institutional Buyer',
  'None of these apply to me',
  'Professional Designation',
  'Income',
  'Net Worth',
  'Professional Designation',
  'Entity',
  'None',
];
