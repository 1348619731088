import { createRouter, createWebHistory } from 'vue-router';
import { onPageResolved as analyticsOnPageResolved } from '@/analytics';
import { resolveRouteDependencies } from './dependency-manager';
import { scrollBehavior } from './scroll-behavior';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeResolve((to, from, next) => {
  resolveRouteDependencies(to, from, next);
});

router.afterEach((to) => {
  analyticsOnPageResolved(to);
});

export default router;
