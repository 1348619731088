import { getCookie } from 'Utils/session';
import { useUserProfile } from 'Store';
import * as CONSTS from './constants';

export function getUserProfileParams(data = Object()) {
  const { profileData } = useUserProfile();

  let phone = profileData.value?.phone || data?.phone;

  if (phone && phone.length === 10) {
    phone = `1${phone}`;
  }

  const finSituation = CONSTS.FINANCIAL_SITUATION[profileData.value?.financial_situation ? profileData.value.financial_situation - 1 : 0];

  return {
    address: {
      city: profileData.value.city || data?.city,
      country: profileData.value.country || data?.country,
      postal_code: profileData.value.zip_code || data?.zip_code,
      state: profileData.value.state || data?.state,
      street_1: profileData.value.address || data?.address,
      street_2: profileData.value.address2 || data?.address2,
    },
    financial_situation: finSituation,
    birthday: profileData.value.birthday || data?.birthday,
    email: profileData.value.email,
    first_name: profileData.value.first_name || data?.first_name,
    last_name: profileData.value.last_name || data?.last_name,
    phone,
    user_city: profileData.value.city || data?.city,
    user_country: profileData.value.country || data?.country,
    user_postal_code: profileData.value.zip_code || data?.zip_code,
    user_state: profileData.value.state || data?.state,
    user_street: profileData.value.address || data?.address,
    user_street2: profileData.value.address2 || data?.address2,
    date_joined: profileData.value.date_joined || data?.date_joined,
  };
}

export function getUniversalParams() {
  return {
    gclid: getCookie('gclid'),
  };
}
