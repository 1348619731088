import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/company/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "PageAboutUs" */ 'Pages/AboutUs/PageAboutUs.vue'),
    meta: {
      title: 'About Us',
    },
    async beforeEnter() {
      await fetchAndSetPageData('about-us');
    },
    children: [
      {
        path: ':slug',
        name: 'about-us-team-member',
        component: () => import(/* webpackChunkName: "PageAboutUs" */ 'Pages/AboutUs/PageAboutUs.vue'),
        meta: {
          skipScrollTab: true,
        },
      },
    ],
  },
];
