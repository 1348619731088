import { ref, Ref, shallowReadonly } from 'vue';
import { INotificationItem } from 'Models';
import { getNotifications } from '@/services/api/notifications';
import { authWSClient } from '@/services/wsClient';
import { NotificationItemClass } from './classes/NotificationItem.class';
import { useInvestmentListClosed } from '../investmentList';

const notificationSystemList: Ref<NotificationItemClass[]> = ref([]);
const notificationSystemNumber = ref(0);
const notificationSystemNumberUnread = ref(0);
const notificationUpdatesList: Ref<NotificationItemClass[]> = ref([]);
const notificationUpdateNumber = ref(0);
const notificationUpdateNumberUnread = ref(0);

export function useNotificationData() {
  const { getInvestmentListClosed } = useInvestmentListClosed();

  async function getNotificationData() {
    const systemPromise = getNotifications('general-system', 20);
    const updatesPromise = getNotifications('general-updates', 20);
    return Promise.all([systemPromise, updatesPromise])
      .then(([systemData, updatesData]) => {
        notificationSystemList.value = systemData.data.map(item => new NotificationItemClass(item));
        notificationSystemNumber.value = systemData.count;
        notificationSystemNumberUnread.value = systemData.new;
        notificationUpdatesList.value = updatesData.data.map(item => new NotificationItemClass(item));
        notificationUpdateNumber.value = updatesData.count;
        notificationUpdateNumberUnread.value = updatesData.new;
      });
  }

  async function setAllNotificationAsRead(): Promise<void> {
    if (!authWSClient.isOpen) await authWSClient.open();
    authWSClient.setAllMessagesAsRead();
    await getNotificationData();
  }

  async function setNotificationAsRead(notificationId: number) {
    if (!authWSClient.isOpen) await authWSClient.open();
    authWSClient.setMessageAsRead(notificationId);
    await getNotificationData();
  }

  async function refreshData(list: string[] = []) {
    list.forEach((item: string) => {
      if (item === 'investment') {
        getInvestmentListClosed();
      }
    });
  }

  async function handleRefreshData(message: INotificationItem) {
    switch (message.channel) {
      case 'refresh':
        refreshData(message.message as unknown as string[]);
        break;
    }
  }

  async function setupSubscribes() {
    // quick solution get all data form server on some updates
    // need to be handle each message
    authWSClient.subscribe(
      'general-system',
      { numMessagesFromArchive: 0 },
      getNotificationData,
    );
    authWSClient.subscribe(
      'general-updates',
      { numMessagesFromArchive: 0 },
      getNotificationData,
    );
    authWSClient.subscribe(
      'refresh',
      {},
      message => handleRefreshData(message as INotificationItem),
    );
  }

  return {
    notificationSystemList: shallowReadonly(notificationSystemList),
    notificationSystemNumber: shallowReadonly(notificationSystemNumber),
    notificationSystemNumberUnread: shallowReadonly(notificationSystemNumberUnread),
    notificationUpdatesList: shallowReadonly(notificationUpdatesList),
    notificationUpdateNumber: shallowReadonly(notificationUpdateNumber),
    notificationUpdateNumberUnread: shallowReadonly(notificationUpdateNumberUnread),
    getNotificationData,
    setAllNotificationAsRead,
    setNotificationAsRead,
    setupSubscribes,
  };
}
