<script lang="ts" setup>
import { BaseModal } from 'Components/Base/BaseModal';

const { message } = defineProps({
  message: {
    type: String,
    required: true,
  },
});

function onCancel() {
  // Reload the page to unload the segment scripts now that the user has opted out.
  window.location.reload();
}
</script>

<template>
  <BaseModal
    class="ModalLogin modal-login"
    show
    size="small"
    @close="onCancel"
  >
    <div class="wrapper">
      <p class="message">
        {{ message }}
      </p>
      <button
        class="button"
        @click="onCancel"
      >
        Close
      </button>
    </div>
  </BaseModal>
</template>

<style lang="sass" scoped>
@import '~Styles/_variables'
.modal-login
  ::-webkit-scrollbar
    display: none
    width: 0px
    background: transparent

  :deep(.modal__container)
    background-color: $gray-10
    max-width: 410px !important
    max-height: 100%
    overflow-y: auto

  .wrapper
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 16px

    .message
      font-family: 'Roboto'
      font-size: 16px

    .button
      display: flex
      justify-content: center
      align-items: center
      border: unset
      height: 40px
      width: min-content
      font-style: normal
      font-weight: 500
      letter-spacing: 0.3px
      font-family: "Roboto"
      color: #fff
      background-color: #2E7A56
      border-radius: 0px
      padding: 0 24px
      font-size: 16px
</style>
