export function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp(`${name}=([^;]+)`));
  if (match && match[1]) return match[1];
  return null;
}

export function setCookie(name: string, value: string, expiryDays: number, path: string) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + (expiryDays || 365));
  const cookie = [
    `${name}=${value}`,
    `expires=${exdate.toUTCString()}`,
    `path=${(path || '/')}`,
  ];
  document.cookie = cookie.join(';');
}
