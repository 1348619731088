import { IPostItem } from 'Models';
import { ref, readonly } from 'vue';
import { getHomePageArticles } from '@/services/api';

const learningHomePagePostList = ref([] as IPostItem[]);

export function useLearningHomePagePostList() {
  async function getLearningHomePagePostList() {
    const data = await getHomePageArticles();
    learningHomePagePostList.value = data?.data || [];
    return data;
  }

  return {
    learningHomePagePostList: readonly(learningHomePagePostList),
    getLearningHomePagePostList,
  };
}
