import { authWSClient } from '@/services/wsClient';
import { IMarketNotification, IMarketMessage } from 'Models';
import { useMarketMessages } from './marketMessages';

export function useMarketNotification() {
  const { addMarketMessage } = useMarketMessages();

  function handleNotification(message: IMarketNotification) {
    switch (message.event) {
      case 'message':
        addMarketMessage(message.message as IMarketMessage);
        break;
    }
  }

  function setupMarketSubscribes() {
    authWSClient.subscribe(
      'market',
      {},
      message => handleNotification(message as IMarketNotification),
    );
  }

  return {
    setupMarketSubscribes,
  };
}
