import { computed, watch, ref } from 'vue';
import CONFIG from 'Config';
import router from '@/router';

const isCheck = ref(false);
const isSuperUser = localStorage.getItem('active-super-user-AcreTrader') === 'true';
const isActiveMaintenance = CONFIG.isActiveMaintenanceMode && !isSuperUser;
const isOnMaintenancePage = computed(() => router.currentRoute.value.name === 'maintenance');
const isOnMHomePage = computed(() => router.currentRoute.value.name === 'home');

watch(() => [isCheck.value], () => {
  if (window.__prerender) return;
  if (isActiveMaintenance && isCheck.value && !isOnMaintenancePage.value) router.replace({ name: 'maintenance' });
}, { immediate: true });

watch(() => [isOnMaintenancePage.value, isCheck.value, router.currentRoute.value], () => {
  if (window.__prerender) return;
  if (!isActiveMaintenance && isOnMaintenancePage.value && isCheck.value) router.replace({ name: 'home' });
}, { immediate: true });

export function useMaintenance() {
  function checkHashForSuperUser() {
    if (window.__prerender) return;
    if (window.location.hash === '#super-user-on') {
      localStorage.setItem('active-super-user-AcreTrader', 'true');
      if (!isOnMHomePage.value) window.location.href = '/';
    } else if (window.location.hash === '#super-user-off') {
      localStorage.removeItem('active-super-user-AcreTrader');
      if (!isOnMHomePage.value) window.location.href = '/';
    }
    isCheck.value = true;
  }

  return {
    isActiveMaintenance,
    isSuperUser,
    checkHashForSuperUser,
    isCheck,
  };
}
