import { computed } from 'vue';
import { useFarmList } from './farmList';

const { farmList } = useFarmList();

const farmListOpened = computed(() => farmList.value.filter(item => item.percent_subscribed < 100 && item.type !== 'dummy'));

export function useFarmListOpened() {
  return {
    farmListOpened,
  };
}
